import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="liff"
export default class extends Controller {
  static targets = ["accessTokenForm", "accessTokenInput"];

  connect() {
    console.debug(
      `Connected to LIFF controller with ID: ${this.getCookie("liff_id")}`,
    );
    this.liffInit();
  }

  async liffInit() {
    try {
      await liff.init({
        liffId: this.getCookie("liff_id"),
        withLoginOnExternalBrowser: true,
      });

      // submit the access token to the backend if the form and input exist
      if (this.hasAccessTokenFormTarget && this.hasAccessTokenInputTarget) {
        const lineAccessToken = liff.getAccessToken();
        this.accessTokenInputTarget.value = lineAccessToken;
        this.accessTokenFormTarget.requestSubmit();
      }
    } catch (err) {
      console.error(err.message);
    }
  }

  getCookie(name) {
    const cookies = document.cookie.split(";");

    for (let cookie of cookies) {
      const [cookieName, cookieValue] = cookie.trim().split("=");
      if (cookieName === name) {
        return decodeURIComponent(cookieValue);
      }
    }

    return null;
  }
}
